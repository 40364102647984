import {
  LuzmoApi,
  type CompanyCustomDashboardDetails,
  type DeleteCompanyCustomDashboard200Response,
  type LuzmoTokens,
} from "@simbelapp/auth-sdk";
import jwt_decode from "jwt-decode";
import { defineStore } from "pinia";
import * as amplitude from "@amplitude/analytics-browser";
import { useCreateCompaniesStore } from "../companies/create-company.store";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { useAuthApi } from "~/composables/auth/useAuthApi";
import { useTokens } from "~/composables/auth/useTokens";
import { useTeamsCookies } from "~/composables/cookies/useTeamsCookies";
import { CONNEXION_STEPS } from "~/utils/enums/auth.enums";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { RolesEnums } from "~/utils/enums/roles.enums";
import type { IAuth, IToken } from "~/utils/interfaces/auth-interfaces";

export const useAuthStore = defineStore("authStore", {
  state: (): IAuth => {
    return {
      connexion: {
        login_email: null,
        login_password: null,
        register_password_first: null,
        register_password_confirm: null,
        connexion_step: CONNEXION_STEPS.LOGIN_EMAIL,
        firstname: null,
        lastname: null,
        show_reset_password_sent: false,
        show_first_password_sent: false,
      },
      user: null,
      tokens: null,
      jwt_payload: null,
      jwt_refresh_payload: null,
    };
  },
  getters: {
    isToken(): boolean {
      return !!this.tokens?.access_token && !!this.tokens?.refresh_token;
    },
    getRoles(): RolesEnums[] | undefined {
      return this.user?.roles.map((role) => role.name);
    },
    isManager(): boolean {
      return !!this.getRoles?.includes(RolesEnums.MANAGER);
    },
    isOnlyManager(): boolean {
      return (
        this.isManager &&
        !this.isTrainingAdmin &&
        !this.isCompanyAdmin &&
        !this.isGroupAdmin &&
        !this.isTrainingPlanAdmin
      );
    },
    isOnlyEmployee(): boolean {
      return (
        this.isEmployee &&
        !this.isManager &&
        !this.isTrainingAdmin &&
        !this.isCompanyAdmin &&
        !this.isGroupAdmin &&
        !this.isTrainingPlanAdmin
      );
    },
    isNotManager(): boolean {
      return this.isTrainingAdmin || this.isCompanyAdmin || this.isGroupAdmin || this.isTrainingPlanAdmin;
    },
    isTrainingAdmin(): boolean {
      return !!this.getRoles?.includes(RolesEnums.TRAINING_ADMIN);
    },
    isCompanyAdmin(): boolean {
      return !!this.getRoles?.includes(RolesEnums.COMPANY_ADMIN);
    },
    isGroupAdmin(): boolean {
      return !!this.getRoles?.includes(RolesEnums.GROUP_ADMIN);
    },
    isGroupViewer(): boolean {
      return !!this.getRoles?.includes(RolesEnums.GROUP_VIEWER);
    },
    isTrainingPlanAdmin(): boolean {
      return !!this.getRoles?.includes(RolesEnums.TRAINING_PLAN_ADMIN);
    },
    isSimbelAdmin(): boolean {
      return !!this.getRoles?.includes(RolesEnums.SIMBEL_ADMIN);
    },
    isEmployee(): boolean {
      return !!this.getRoles?.includes(RolesEnums.EMPLOYEE) || !this.getRoles?.length;
    },
    isAuthor(): boolean {
      return !!this.getRoles?.includes(RolesEnums.AUTHOR);
    },
  },
  actions: {
    isAccessTokenValid(): boolean {
      if (this.jwt_payload?.exp) {
        return this.isToken && new Date(this.jwt_payload?.exp * 1000) > new Date();
      } else {
        return false;
      }
    },
    isRefreshTokenValid(): boolean {
      if (this.jwt_refresh_payload?.exp) {
        return this.isToken && new Date(this.jwt_refresh_payload?.exp * 1000) > new Date();
      } else {
        return false;
      }
    },
    isLoggedIn(): boolean {
      return this.isAccessTokenValid() && this.isRefreshTokenValid() && !!this.user;
    },
    setTokens(_tokens: IToken) {
      const tokens = useTokens();

      tokens.setCookiesTokens(_tokens);
      this.tokens = {
        access_token: _tokens.access_token,
        refresh_token: _tokens.refresh_token,
      };

      if (this.isToken && this.tokens?.access_token && this.tokens?.refresh_token) {
        this.jwt_payload = jwt_decode(this.tokens?.access_token);
        this.jwt_refresh_payload = jwt_decode(this.tokens?.refresh_token);
      }
    },
    async fetchProfile() {
      const authApi = useAuthApi();
      const { data } = await authApi.fetchProfile();

      if (data.value) {
        this.user = data.value;
        // Set admin role if token is from log as
        if (this.jwt_payload?.log_as_admin_sub && this.user?.roles) {
          this.user.roles.push({
            role_id: "",
            name: RolesEnums.SIMBEL_ADMIN,
          });
        }
      } else {
        this.$reset();
      }
    },

    // IQ param to delete after the end of the IQ POC
    async fetchMainDashboardTokens(iq = false): Promise<LuzmoTokens> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, LuzmoApi);
      return await apiInstance.getMainDashboardTokens({
        iq,
      });
    },

    async fetchCompanyCustomDashboardLuzmoTokens(customDashboardId: string): Promise<LuzmoTokens> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, LuzmoApi);
      return await apiInstance.getCompanyCustomDashboardLuzmoTokens({
        companyCustomDashboardId: customDashboardId,
      });
    },

    async fetchAdminCompanyCustomDashboardTokens(companyId: string): Promise<CompanyCustomDashboardDetails[]> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, LuzmoApi);
      return await apiInstance.adminGetCompanyCustomDashboards({
        companyId,
      });
    },

    async fetchCompanyCustomDashboards(): Promise<CompanyCustomDashboardDetails[]> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, LuzmoApi);
      return await apiInstance.getCompanyCustomDashboards();
    },

    async createCompanyCustomDashboard(
      name: string,
      dashboardUrl: string,
      companyId: string,
    ): Promise<CompanyCustomDashboardDetails> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, LuzmoApi);
      return await apiInstance.createCompanyCustomDashboard({
        companyId,
        createCompanyCustomDashboardDto: {
          name,
          dashboardUrl,
        },
      });
    },

    async updateCompanyCustomDashboard(
      companyCustomDashboardId: string,
      name: string,
      dashboardUrl: string,
      companyId: string,
    ): Promise<CompanyCustomDashboardDetails> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, LuzmoApi);
      return await apiInstance.updateCompanyCustomDashboard({
        companyCustomDashboardId,
        companyId,
        createCompanyCustomDashboardDto: {
          name,
          dashboardUrl,
        },
      });
    },

    async removeCompanyCustomDashboard(
      companyCustomDashboardId: string,
      companyId: string,
    ): Promise<DeleteCompanyCustomDashboard200Response> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, LuzmoApi);
      return await apiInstance.deleteCompanyCustomDashboard({
        companyCustomDashboardId,
        companyId,
      });
    },

    async refreshToken() {
      if (!this.isAccessTokenValid() && this.isRefreshTokenValid()) {
        const config = useRuntimeConfig();
        const authApi = useAuthApi();
        const { data } = await authApi.refresh();

        if (data.value && this.tokens?.refresh_token) {
          this.setTokens({
            access_token: data.value.access_token,
            refresh_token: this.tokens?.refresh_token,
          });
          await this.fetchProfile();

          if (config.public.ENV !== "PROD") {
            // eslint-disable-next-line
            console.log("refreshToken: Access Token Refreshed");
          }
        } else {
          this.$reset();
        }
      }
    },
    async login() {
      if (this.isAccessTokenValid() && this.isRefreshTokenValid()) {
        await this.fetchProfile();
      } else {
        this.$reset();
      }
    },
    logOut() {
      const tokens = useTokens();
      tokens.clearCookiesTokens();
      this.$reset();
      useCookie("redirect_url").value = "none";
      return navigateTo("/");
    },

    initAmplitude() {
      const config = useRuntimeConfig();
      const environment = config.public.ENV;
      if (["DEV", "PROD"].includes(environment)) {
        const currentUserEmail = this.user?.email;
        const companyName = this.user?.main_entity_id?.name;
        const roles = this.user?.roles;
        if (currentUserEmail) {
          amplitude.init(config.public.AMPLITUDE_API_KEY as string, currentUserEmail, {
            autocapture: true,
          });
          const identifyEvent = new amplitude.Identify();
          identifyEvent.set("email", currentUserEmail);
          identifyEvent.set("companyName", companyName ?? "");
          identifyEvent.set("roles", roles ?? []);
          amplitude.identify(identifyEvent);
        } else {
          amplitude.init(config.public.AMPLITUDE_API_KEY as string, {
            autocapture: true,
          });
        }
      }
    },

    async logAs(user_id: string) {
      const createCompaniesStore = useCreateCompaniesStore();
      createCompaniesStore.create_company.client_id = null;
      createCompaniesStore.create_company.client_secret = null;
      createCompaniesStore.create_company.tenant_id = null;

      const teamsCookies = useTeamsCookies();

      teamsCookies.setQueryOpts("offset", 0, "users_opts");
      teamsCookies.setQueryOpts("offset", 0, "company_catalog_opts");
      teamsCookies.setQueryOpts("offset", 0, "requests_opts");
      teamsCookies.setQueryOpts("offset", 0, "habilitation_opts");
      teamsCookies.setQueryOpts("offset", 0, "training_plan_opts_individual");
      teamsCookies.setQueryOpts("offset", 0, "training_plan_opts_sessions");
      teamsCookies.setQueryOpts("offset", 0, "my_trainings_opts");

      if (this.isLoggedIn()) {
        const authApi = useAuthApi();
        const { data } = await authApi.logAs(user_id);

        if (data.value && this.tokens?.refresh_token) {
          this.setTokens({
            access_token: data.value.access_token,
            refresh_token: this.tokens?.refresh_token,
          });
          await this.login();

          if (this.user?.main_entity_id?.company_id) {
            await createCompaniesStore.fetchCalendarOutlookSettings(this.user?.main_entity_id?.company_id);
          }

          return navigateTo("/home");
        }
      }
    },
  },
});
