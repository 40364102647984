import {
  SettingsApi,
  type HeadersValuesDTO,
  type FilterValuesDTO,
  type SettingsParametersDto,
  ItemType,
  SettingScopesEnum,
} from "@simbelapp/auth-sdk";
import { defineStore } from "pinia";
import { useSDKApi, useSDKApiOlder } from "~/composables/api/useSDKApi";
import { useCatalog } from "~/composables/catalog/useCatalog";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { UserStatusEnum } from "~/utils/enums/users.enums";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import type { ISettings } from "~~/utils/interfaces/settings-interfaces";

export const useSettingsStore = defineStore("settingsStore", {
  state: (): ISettings => {
    return {
      gridDetails: {
        settingsDetails: {
          parameters: null,
          itemId: null,
          itemType: null,
          scope: null,
        },
        headersValues: [],
        filtersValues: [],
        sortingValues: [],
        paginationValues: [],
        searchValues: [],
        sectionValues: [],
      },
      presetSettings: {
        headersValues: [],
        filtersValues: [],
        sortingValues: [],
        paginationValues: [],
        searchValues: [],
        sectionValues: [],
      },
    };
  },
  getters: {
    getHeaders(): HeadersValuesDTO[] {
      return this.gridDetails.headersValues ?? [];
    },

    getFilters(): FilterValuesDTO[] {
      return this.gridDetails.filtersValues ?? [];
    },
  },
  actions: {
    resetSettings(notHeaders?: boolean) {
      this.gridDetails.settingsDetails = {
        parameters: null,
        itemId: null,
        itemType: null,
        scope: null,
      };
      this.gridDetails.headersValues = notHeaders ? this.gridDetails.headersValues : [];
      this.gridDetails.filtersValues = [];
      this.gridDetails.sortingValues = [];
      this.gridDetails.paginationValues = [];
      this.gridDetails.searchValues = [];
      this.gridDetails.sectionValues = [];
    },
    resetPresetSettings() {
      this.presetSettings.filtersValues = [];
      this.presetSettings.sortingValues = [];
      this.presetSettings.paginationValues = [];
      this.presetSettings.searchValues = [];
      this.presetSettings.sectionValues = [];
    },
    // front migration, to be delete when headers are in the database
    syncHeadersToHeadersValues(headers: HeadersValuesDTO[], settingsHeaders: HeadersValuesDTO[]) {
      // Remove bad headers in settings from db
      const settingsHeaders2 = settingsHeaders.filter((header) => headers.some((h) => h.key === header.key));
      // Correct the scope of the headers and add missing
      headers.forEach((header, index) => {
        const matchingHeaderIndex = settingsHeaders2.findIndex((h) => h.key === header.key);

        if (matchingHeaderIndex !== -1) {
          // If the header exists, update its scope and check the index
          const matchingHeader = settingsHeaders2[matchingHeaderIndex];
          matchingHeader.scope = header.scope;

          // Reorder if the index is incorrect
          if (matchingHeaderIndex !== index) {
            settingsHeaders2.splice(matchingHeaderIndex, 1); // Remove the header from the old index
            settingsHeaders2.splice(index, 0, matchingHeader); // Insert it at the correct position
          }
        } else {
          // If the header does not exist, add it at the correct position
          settingsHeaders2.splice(index, 0, { ...header });
        }
      });

      // Replace settingsHeaders with the corrected list
      settingsHeaders.length = 0;
      settingsHeaders.push(...settingsHeaders2);
    },
    // front migration, if sectionValues in db doesn't have itemType then add it
    syncSectionWithItemType(section: string, itemType: ItemType) {
      const sectionValue = this.gridDetails.sectionValues.find((s) => s.sectionName === section);
      if (sectionValue && !sectionValue.itemType) {
        sectionValue.itemType = itemType;
      }
    },
    // front migration, if sortingValues in db doesn't have scope then add it
    syncSortingWithScope(scope: SettingScopesEnum) {
      this.gridDetails.sortingValues.forEach((sorting) => {
        if (!sorting.scope) {
          sorting.scope = scope;
        }
      });
    },
    async fetchGridSettings(
      itemId: string,
      itemType: ItemType,
      scope: SettingScopesEnum,
      headers?: HeadersValuesDTO[],
      section?: string,
      itemTypeFilter?: ItemType,
    ) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApiOlder(MicroserviceEnum.USERS, SettingsApi);
      if (Object.values(this.presetSettings).some((arr) => arr.length > 0)) {
        await this.updatePresetGridSettings(itemId, itemType, scope, headers);
        this.resetPresetSettings();
      } else {
        try {
          const settings = await apiInstance.getScopeSettings({
            itemId,
            itemType,
            scope,
          });
          this.gridDetails.settingsDetails = settings;

          const headerExist = this.gridDetails.settingsDetails.parameters?.some(
            (p) => p?.headersValues?.length && p?.headersValues?.length > 0,
          );

          if (!headerExist) {
            this.gridDetails.headersValues = headers ?? [];
          } else {
            this.gridDetails.headersValues =
              settings.parameters.find((parameter) => parameter.headersValues && parameter.headersValues?.length > 0)
                ?.headersValues || [];
            this.gridDetails.filtersValues =
              settings.parameters.find((parameter) => parameter.filterValues && parameter.filterValues?.length > 0)
                ?.filterValues || [];
            this.gridDetails.sortingValues =
              settings.parameters.find((parameter) => parameter.sortingValues && parameter.sortingValues?.length > 0)
                ?.sortingValues || [];
            this.gridDetails.paginationValues =
              settings.parameters.find(
                (parameter) => parameter.paginationValues && parameter.paginationValues?.length > 0,
              )?.paginationValues || [];
            this.gridDetails.searchValues =
              settings.parameters.find((parameter) => parameter.searchValues && parameter.searchValues?.length > 0)
                ?.searchValues || [];
            this.gridDetails.sectionValues =
              settings.parameters.find((parameter) => parameter.sectionValues && parameter.sectionValues?.length > 0)
                ?.sectionValues || [];

            if (headers && headers.length > 0) {
              this.syncHeadersToHeadersValues(headers, this.gridDetails.headersValues);
            }
            if (itemTypeFilter && section && this.gridDetails.sectionValues.length) {
              this.syncSectionWithItemType(section, itemTypeFilter);
            }
            if (this.gridDetails.sortingValues.length) {
              this.syncSortingWithScope(scope);
            }
          }
          if (this.gridDetails.sectionValues?.length === 0) {
            if (section) {
              this.gridDetails.sectionValues[0] = itemTypeFilter
                ? { sectionName: section, itemType: itemTypeFilter }
                : { sectionName: section };

              await this.updateGridSettings(itemId, itemType);
            }
          }
          if (this.gridDetails.headersValues?.length === 0) {
            if (section) {
              this.gridDetails.headersValues = headers ?? [];

              await this.updateGridSettings(itemId, itemType);
            }
          }
        } catch (error: any) {
          if (error.response && error.response.status === 404) {
            this.gridDetails.settingsDetails.itemId = itemId;
            this.gridDetails.settingsDetails.itemType = itemType;
            this.gridDetails.settingsDetails.scope = scope;

            this.gridDetails.headersValues = headers ?? [];
            if (section) {
              this.gridDetails.sectionValues[0] = itemTypeFilter
                ? { sectionName: section, itemType: itemTypeFilter }
                : { sectionName: section };
            }
            if (scope === SettingScopesEnum.UsersTab) {
              this.gridDetails.filtersValues.push({
                filterId: "status",
                values: [UserStatusEnum.REGISTERED, UserStatusEnum.UNREGISTERED],
              });
            }
            await this.updateGridSettings(itemId, itemType);
          } else {
            feedback.error(`Une erreur est survenue`, "small");
          }
        }
      }
    },

    /**
     * Updates grid settings with preset values.
     *
     * @param itemId - The ID of the item to update.
     * @param itemType - The type of the item to update.
     * @param scope - The scope of the settings.
     * @param headers - Optional array of header values to update.
     * @returns A promise that resolves when the grid settings have been updated.
     */
    async updatePresetGridSettings(
      itemId: string,
      itemType: ItemType,
      scope: SettingScopesEnum,
      headers?: HeadersValuesDTO[],
    ): Promise<void> {
      this.gridDetails.settingsDetails.itemId = itemId;
      this.gridDetails.settingsDetails.itemType = itemType;
      this.gridDetails.settingsDetails.scope = scope;

      this.gridDetails.sortingValues = this.presetSettings?.sortingValues || [];
      this.gridDetails.filtersValues = this.presetSettings?.filtersValues || [];
      this.gridDetails.paginationValues = this.presetSettings?.paginationValues || [];
      this.gridDetails.searchValues = this.presetSettings?.searchValues || [];
      this.gridDetails.sectionValues = this.presetSettings?.sectionValues || [];
      this.gridDetails.headersValues = headers ?? [];
      await this.updateGridSettings(itemId, itemType);
    },

    async updateGridSettings(itemId: string, itemType: ItemType) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, SettingsApi);
      const headersCatalog = useCatalog();
      try {
        const paramertersEdit: SettingsParametersDto[] = [
          {
            filterValues: this.gridDetails.filtersValues.map((filter) => ({
              ...filter,
              values: filter.values.map((value) => value?.toString() ?? "null"),
            })),

            // In company catalog we have a shity system with two different headers between 2 tabs
            // The header in the database needs to stay the same so that's why in this case we force the header to always have the same values
            headersValues:
              this.gridDetails.settingsDetails.scope === SettingScopesEnum.CompanyCatalogTab
                ? headersCatalog.headers.value.map((h) => {
                    const gridHeader = this.gridDetails.headersValues.find((gh) => gh.key === h.key);
                    return {
                      name: h.name,
                      key: h.key || "",
                      sort: gridHeader?.sort || false,
                      minWidthPx: gridHeader?.minWidthPx ?? h.minWidthPx ?? 1,
                      maxWidthFr: gridHeader?.maxWidthFr ?? h.maxWidthFr ?? 1,
                      visible: h.visible ?? true,
                      scope: h.scope as SettingScopesEnum,
                    };
                  })
                : this.gridDetails.headersValues,
            sortingValues: this.gridDetails.sortingValues,
            paginationValues: this.gridDetails.paginationValues,
            searchValues: this.gridDetails.searchValues,
            sectionValues: this.gridDetails.sectionValues,
          },
        ];

        if (this.gridDetails.settingsDetails.scope) {
          const router = useRouter();

          if (router.currentRoute.value.path !== "/admin/log-as" && router.currentRoute.value.path !== "/my-catalog") {
            const settings = await apiInstance
              .upsertSettings({
                upsertSettingDto: {
                  parameters: paramertersEdit || [],
                  itemId,
                  itemType,
                  scope: this.gridDetails.settingsDetails.scope,
                },
              })
              .catch(() => {
                return null;
              });

            if (settings) {
              this.gridDetails.settingsDetails = settings;

              this.gridDetails.headersValues =
                settings.parameters.find((parameter) => parameter.headersValues && parameter.headersValues?.length > 0)
                  ?.headersValues || [];
              this.gridDetails.filtersValues =
                settings.parameters.find((parameter) => parameter.filterValues && parameter.filterValues?.length > 0)
                  ?.filterValues || [];
              this.gridDetails.sortingValues =
                settings.parameters.find((parameter) => parameter.sortingValues && parameter.sortingValues?.length > 0)
                  ?.sortingValues || [];
              this.gridDetails.paginationValues =
                settings.parameters.find(
                  (parameter) => parameter.paginationValues && parameter.paginationValues?.length > 0,
                )?.paginationValues || [];
              this.gridDetails.searchValues =
                settings.parameters.find((parameter) => parameter.searchValues && parameter.searchValues?.length > 0)
                  ?.searchValues || [];
              this.gridDetails.sectionValues =
                settings.parameters.find((parameter) => parameter.sectionValues && parameter.sectionValues?.length > 0)
                  ?.sectionValues || [];
            }
          }
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
  },
});
