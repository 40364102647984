
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexJfTsfuGhATMeta } from "/app/pages/admin/contents/index.vue?macro=true";
import { default as indexrUgIAyoLGdMeta } from "/app/pages/admin/display/index.vue?macro=true";
import { default as EditTrainingPartnerModal65ntATJXWlMeta } from "/app/pages/admin/log-as/EditTrainingPartnerModal.vue?macro=true";
import { default as indexQGWzPLK3LDMeta } from "/app/pages/admin/log-as/index.vue?macro=true";
import { default as index6E7Uzbc1rVMeta } from "/app/pages/admin/my-requests/index.vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as indexQdMXO1eOkzMeta } from "/app/pages/company-catalog/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as index0MCgyG25GHMeta } from "/app/pages/habilitations/index.vue?macro=true";
import { default as index4aGTGBC5tJMeta } from "/app/pages/home/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexntrChBViWvMeta } from "/app/pages/iq/index.vue?macro=true";
import { default as indexHygUjNI2NoMeta } from "/app/pages/my-catalog/index.vue?macro=true";
import { default as indexeb3KCcoyd0Meta } from "/app/pages/my-sessions/index.vue?macro=true";
import { default as _91my_training_id_93E1aDhWwI63Meta } from "/app/pages/my-trainings/[my_training_id].vue?macro=true";
import { default as index5S2gfrhkXnMeta } from "/app/pages/my-trainings/index.vue?macro=true";
import { default as scorm_45playerwRPr2sdjFsMeta } from "/app/pages/my-trainings/scorm-player.vue?macro=true";
import { default as _91request_id_93UPa61zgqPvMeta } from "/app/pages/requests/[request_id].vue?macro=true";
import { default as indexpYlP26iY0pMeta } from "/app/pages/requests/index.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as index9xD4BVWummMeta } from "/app/pages/simbel-catalog/index.vue?macro=true";
import { default as callbackBgNum0R3ApMeta } from "/app/pages/sso/callback.vue?macro=true";
import { default as redirectaorrRKLnFiMeta } from "/app/pages/sso/redirect.vue?macro=true";
import { default as indexZxDxKCcaBGMeta } from "/app/pages/training-plan/index.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: "admin-contents___fr",
    path: "/admin/contents",
    meta: indexJfTsfuGhATMeta || {},
    component: () => import("/app/pages/admin/contents/index.vue")
  },
  {
    name: "admin-display___fr",
    path: "/admin/display",
    meta: indexrUgIAyoLGdMeta || {},
    component: () => import("/app/pages/admin/display/index.vue")
  },
  {
    name: "admin-log-as-EditTrainingPartnerModal___fr",
    path: "/admin/log-as/EditTrainingPartnerModal",
    component: () => import("/app/pages/admin/log-as/EditTrainingPartnerModal.vue")
  },
  {
    name: "admin-log-as___fr",
    path: "/admin/log-as",
    meta: indexQGWzPLK3LDMeta || {},
    component: () => import("/app/pages/admin/log-as/index.vue")
  },
  {
    name: "admin-my-requests___fr",
    path: "/admin/my-requests",
    meta: index6E7Uzbc1rVMeta || {},
    component: () => import("/app/pages/admin/my-requests/index.vue")
  },
  {
    name: "catalog___fr",
    path: "/catalog",
    meta: indexvJKqjSbZLzMeta || {},
    component: () => import("/app/pages/catalog/index.vue")
  },
  {
    name: "company-catalog___fr",
    path: "/company-catalog",
    component: () => import("/app/pages/company-catalog/index.vue")
  },
  {
    name: "dashboard___fr",
    path: "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "habilitations___fr",
    path: "/habilitations",
    component: () => import("/app/pages/habilitations/index.vue")
  },
  {
    name: "home___fr",
    path: "/home",
    meta: index4aGTGBC5tJMeta || {},
    component: () => import("/app/pages/home/index.vue")
  },
  {
    name: "index___fr",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "iq___fr",
    path: "/iq",
    meta: indexntrChBViWvMeta || {},
    component: () => import("/app/pages/iq/index.vue")
  },
  {
    name: "my-catalog___fr",
    path: "/my-catalog",
    component: () => import("/app/pages/my-catalog/index.vue")
  },
  {
    name: "my-sessions___fr",
    path: "/my-sessions",
    meta: indexeb3KCcoyd0Meta || {},
    component: () => import("/app/pages/my-sessions/index.vue")
  },
  {
    name: "my-trainings-my_training_id___fr",
    path: "/my-trainings/:my_training_id()",
    component: () => import("/app/pages/my-trainings/[my_training_id].vue")
  },
  {
    name: "my-trainings___fr",
    path: "/my-trainings",
    meta: index5S2gfrhkXnMeta || {},
    component: () => import("/app/pages/my-trainings/index.vue")
  },
  {
    name: "my-trainings-scorm-player___fr",
    path: "/my-trainings/scorm-player",
    meta: scorm_45playerwRPr2sdjFsMeta || {},
    component: () => import("/app/pages/my-trainings/scorm-player.vue")
  },
  {
    name: "requests-request_id___fr",
    path: "/requests/:request_id()",
    meta: _91request_id_93UPa61zgqPvMeta || {},
    component: () => import("/app/pages/requests/[request_id].vue")
  },
  {
    name: "requests___fr",
    path: "/requests",
    component: () => import("/app/pages/requests/index.vue")
  },
  {
    name: "settings___fr",
    path: "/settings",
    meta: index11eXGkSJBcMeta || {},
    component: () => import("/app/pages/settings/index.vue")
  },
  {
    name: "simbel-catalog___fr",
    path: "/simbel-catalog",
    component: () => import("/app/pages/simbel-catalog/index.vue")
  },
  {
    name: "sso-callback___fr",
    path: "/sso/callback",
    meta: callbackBgNum0R3ApMeta || {},
    component: () => import("/app/pages/sso/callback.vue")
  },
  {
    name: "sso-redirect___fr",
    path: "/sso/redirect",
    meta: redirectaorrRKLnFiMeta || {},
    component: () => import("/app/pages/sso/redirect.vue")
  },
  {
    name: "training-plan___fr",
    path: "/training-plan",
    component: () => import("/app/pages/training-plan/index.vue")
  },
  {
    name: "users___fr",
    path: "/users",
    component: () => import("/app/pages/users/index.vue")
  }
]