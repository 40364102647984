import { Configuration as OrderConfiguration } from "@simbelapp/order-sdk";
import { Configuration as BillingConfiguration } from "@simbelapp/billing-sdk";
import { Configuration as AuthConfiguration } from "@simbelapp/auth-sdk";
import { Configuration as TrainingsConfiguration } from "@simbelapp/trainings-sdk";

import { useAuthStore } from "~/store/auth/auth.store";
export type MicroServiceType = "orders" | "orders_reporting" | "billing" | "auth" | "trainings";

const ConfigurationMap: Record<MicroServiceType, new (config: any) => any> = {
  orders: OrderConfiguration,
  orders_reporting: OrderConfiguration,
  billing: BillingConfiguration,
  auth: AuthConfiguration,
  trainings: TrainingsConfiguration,
};

export async function useSDKApiOlder<T>(
  ms: MicroServiceType,
  ApiClass: new (config: any) => T,
  isBearer = true,
): Promise<T> {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();

  const Map_MS = {
    auth: config.public.AUTH_URL,
    orders: config.public.ORDERS_URL,
    orders_reporting: config.public.ORDERS_REPORTING_API_URL,
    trainings: config.public.TRAININGS_URL,
    billing: config.public.BILLING_URL as string,
  };

  if (isBearer && !authStore.isAccessTokenValid() && authStore.isRefreshTokenValid()) await authStore.refreshToken();

  const token = authStore.tokens?.access_token;

  const ConfigurationClass = ConfigurationMap[ms];
  const apiConfig = new ConfigurationClass({
    basePath: Map_MS[ms],
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const apiInstance = new ApiClass(apiConfig);
  return apiInstance;
}

export async function useSDKApi<T extends object>(
  ms: MicroServiceType,
  ApiClass: new (config: any) => T,
  isBearer = true,
): Promise<T> {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();

  const Map_MS = {
    auth: config.public.AUTH_URL,
    orders: config.public.ORDERS_URL,
    orders_reporting: config.public.ORDERS_REPORTING_API_URL,
    trainings: config.public.TRAININGS_URL,
    billing: config.public.BILLING_URL as string,
  };

  if (isBearer && !authStore.isAccessTokenValid() && authStore.isRefreshTokenValid()) await authStore.refreshToken();

  const token = authStore.tokens?.access_token;

  const ConfigurationClass = ConfigurationMap[ms];
  const apiConfig = new ConfigurationClass({
    basePath: Map_MS[ms],
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const apiInstance = new ApiClass(apiConfig);

  /**
   * @TODO test this new feature. report any issues to the team mainly @Mickael
   * This is a Proxy object that wraps the API instance and catches any errors that occur during API calls.
   * If an error occurs, it logs the error and throws the error message.
   * This is useful for debugging and error handling.
   */

  return new Proxy(apiInstance, {
    get(target, prop) {
      const originalMethod = target[prop as keyof T];

      if (typeof originalMethod === "function") {
        return async (...args: any[]) => {
          try {
            return await (originalMethod as any).apply(target, args);
          } catch (error: any) {
            const message = await error?.response?.json?.();
            if (message) {
              console.error(`API Error`, message);
              throw error.message;
            } else if (error?.cause) {
              console.error(`API Error`, error?.cause);
              throw error?.cause;
            } else {
              console.error(`API Error`, error);
              throw error;
            }
          }
        };
      }

      return originalMethod;
    },
  });
}
