import { defineStore } from "pinia";
import { toZonedTime } from "date-fns-tz";
import { CompaniesApi } from "@simbelapp/auth-sdk/apis/CompaniesApi";
import { AdminCompaniesApi, MicrosoftApi } from "@simbelapp/auth-sdk";
import { ItemType, TagsApi } from "@simbelapp/order-sdk";
import { useAuthStore } from "../auth/auth.store";
import { useCompaniesApi } from "~~/composables/companies/useCompaniesApi";
import type { ICreateCompanyStore } from "~~/utils/interfaces/companies-interfaces";
import { CompanyIdentificationModeEnum, CompanyAuthStrategy, CompanyLanguage } from "~~/utils/enums/companies.enums";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { TagCategoryEnum } from "~/utils/enums/tags.enums";

export const useCreateCompaniesStore = defineStore("useCreateCompaniesStore", {
  state: (): ICreateCompanyStore => {
    return {
      create_company: {
        name: "",
        manager_validation: false,
        identification_mode: CompanyIdentificationModeEnum.EMAIL,
        is_main_entity: true,
        email_admin: "",
        auth_strategy: CompanyAuthStrategy.LOGIN_PASSWORD,
        notifications: {
          new_employee_request: true,
          refused_employee_request: true,
          waiting_hr_validation: true,
          booking_to_be_confirmed: true,
          booking_confirmed: true,
          booking_dates_updated: true,
        },
        hide_catalog_prices: false,
        trainings_not_billable: false,
        language: CompanyLanguage.FR,
      },
      families_tag: [],
      training_tags: [],
      session_tags: [],
      logo_url: null,
      logo: null,
      banner: null,
      sso_provider: null,
      errors: {},
      show_create_modal: false,
      is_update: false,
      showDeleteTagModal: false,
      showDeleteDashboardModal: false,
      modeEditBanner: false,
    };
  },
  getters: {
    /**
     * Check if the company has outlook info.
     * @returns {boolean} Whether the company has tenant_id, client_id, client_secret return bool.
     */
    calendarSync: (state) => () => {
      return state.create_company?.client_id && state.create_company.client_secret && state.create_company.tenant_id;
    },
  },
  actions: {
    /**
     * Open the create company modal and reset the store state.
     */
    openCreateCompanyModal() {
      this.$reset();
      this.show_create_modal = true;
    },
    /**
     * Create a new company.
     * @returns {Promise<any>} The created company data.
     */
    async createCompany() {
      const companiesApi = useCompaniesApi();

      if (!this.create_company?.welcome_message) {
        this.create_company.welcome_message = `Bienvenue sur votre académie de formation`;
      }

      if (this.create_company?.start_sync_date)
        this.create_company.start_sync_date = toZonedTime(this.create_company.start_sync_date, "Etc/UTC");

      const { data } = await companiesApi.createCompany(this.create_company);
      if (data.value) {
        await this.updateCompanyLogo(data.value.company_id);
        if (this.banner) {
          await this.convertCompanyBanner(this.banner as File);
          await this.updateCompanyBanner(data.value.company_id);
        }
      }
      return data.value;
    },

    /**
     * Get the company data.
     * @returns {Promise<any>} The company data.
     */
    async getCompany() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.createCompany(this.create_company);
      return data.value;
    },

    /**
     * Get training tags for a company.
     * @param {Object} params - The parameters for fetching training tags.
     * @param {string} [params.company_id] - The company ID.
     * @param {string} [params.onlyUsed] - Filter for only used tags.
     * @param {ItemType} [params.itemType] - The item type.
     * @param {boolean} [params.nullable] - Whether to include nullable tags.
     * @param {string} [params.onlyCatalogFilterVisible] - Filter for only catalog filter visible tags.
     * @returns {Promise<TagDetails[] | void>} The training tags.
     */
    async getTrainingTags({
      company_id,
      onlyUsed,
      itemType,
      nullable,
      onlyCatalogFilterVisible,
    }: {
      company_id?: string;
      onlyUsed?: string;
      itemType?: ItemType;
      nullable?: boolean;
      onlyCatalogFilterVisible?: string;
    }) {
      if (this.create_company.company_id !== undefined || company_id !== undefined) {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
        const training_tag = await apiInstance.getCompanyTags({
          companyId: this.create_company.company_id ?? (company_id as string),
          category: TagCategoryEnum.TRAINING_TAG,
          onlyUsed,
          itemType,
          onlyCatalogFilterVisible,
        });
        this.training_tags = nullable ? training_tag.filter((e) => e !== null) : training_tag;
        return training_tag;
      }
    },

    /**
     * Get session tags for a company.
     * @param {string} [company_id] - The company ID.
     * @param {boolean} [nullable] - Whether to include nullable tags.
     * @returns {Promise<void>}
     */
    async getSessionTags(company_id?: string, nullable?: boolean) {
      if (this.create_company.company_id !== undefined || company_id !== undefined) {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
        const session_tag = await apiInstance.getCompanyTags({
          companyId: this.create_company.company_id ?? (company_id as string),
          category: TagCategoryEnum.SESSION_TAG,
        });
        this.session_tags = nullable ? session_tag.filter((e) => e !== null) : session_tag;
      }
    },

    /**
     * Get families tag.
     * @returns {Promise<TagFamilies[]>} The families tag.
     */
    async getFamiliesTag() {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      const response = await apiInstance.getTagFamilies();
      this.families_tag = response;
      return response;
    },

    /**
     * Delete a tag family.
     * @param {string} id - The ID of the tag family to delete.
     * @returns {Promise<DeleteTagFamily200Response>} The response from the API.
     */
    async deleteTagFamily(id: string) {
      const authStore = useAuthStore();
      const main_entity_id = authStore.user?.main_entity_id;
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      const response = await apiInstance.deleteTagFamily({
        tagFamilyId: id,
      });
      await this.getFamiliesTag();
      await this.getTrainingTags({ company_id: main_entity_id?.company_id, nullable: true });
      return response;
    },

    /**
     * Create a new tag family.
     * @param {string} [name] - The name of the tag family.
     * @returns {Promise<CreateTagFamily200Response>} The response from the API.
     */
    async createTagFamily(name?: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      const response = await apiInstance.createTagFamily({
        updateTagFamilyDto: {
          tagFamilyName: name || null,
        },
      });
      await this.getFamiliesTag();
      return response;
    },

    /**
     * Update a tag family.
     * @param {Object} params - The parameters for updating the tag family.
     * @param {string} params.id - The ID of the tag family.
     * @param {string} [params.name] - The name of the tag family.
     * @param {boolean} [params.catalogFilterVisible] - Whether the tag family is visible in the catalog filter.
     * @returns {Promise<UpdateTagFamily200Response>} The response from the API.
     */
    async updateTagFamily({
      id,
      name,
      catalogFilterVisible,
    }: {
      id: string;
      name?: string;
      catalogFilterVisible?: boolean;
    }) {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      const response = await apiInstance.updateTagFamily({
        tagFamilyId: id,
        updateTagFamilyDto: {
          tagFamilyName: name,
          catalogFilterVisible,
        },
      });
      this.getFamiliesTag();
      return response;
    },

    /**
     * Post training tags for a company.
     * @param {string} [company_id] - The company ID.
     * @returns {Promise<any>} The response from the API.
     */
    async postTrainingTags(company_id?: string) {
      if (this.create_company.company_id !== undefined || company_id !== undefined) {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
        const training_tag = await apiInstance.setCompanyTags({
          companyId: this.create_company.company_id ?? (company_id as string),
          category: TagCategoryEnum.TRAINING_TAG,
          setCompanyTagsDto: this.training_tags,
        });
        await this.getTrainingTags({
          company_id: this.create_company.company_id ?? (company_id as string),
          nullable: true,
        });
        return { training_tag };
      }
    },

    /**
     * Post session tags for a company.
     * @param {string} [company_id] - The company ID.
     * @returns {Promise<any>} The response from the API.
     */
    async postSessionTags(company_id?: string) {
      if (this.create_company.company_id !== undefined || company_id !== undefined) {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
        const session_tag = await apiInstance.setCompanyTags({
          companyId: this.create_company.company_id ?? (company_id as string),
          category: TagCategoryEnum.SESSION_TAG,
          setCompanyTagsDto: this.session_tags,
        });
        await this.getSessionTags();
        return { session_tag };
      }
    },

    /**
     * Convert the company logo to PNG format.
     * @param {File} logo - The logo file to convert.
     * @returns {Promise<void>}
     */
    async convertCompanyLogo(logo: File) {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, CompaniesApi);
      const response = await apiInstance.convertCompanyLogoRaw({
        file: logo,
      });
      const convertedImg = await response.raw.blob();
      if (convertedImg) {
        this.logo = new File([convertedImg], logo.name, { type: "image/png" });
      }
    },

    /**
     * Convert the company banner to PNG format.
     * @param {File} banner - The banner file to convert.
     * @returns {Promise<void>}
     */
    async convertCompanyBanner(banner: File) {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, CompaniesApi);
      const response = await apiInstance.convertCompanyBannerRaw({
        file: banner,
      });
      const convertedImg = await response.raw.blob();
      if (convertedImg) {
        this.banner = new File([convertedImg], banner.name, { type: "image/png" });
      }
    },

    /**
     * Update the company logo.
     * @param {string} companyId - The ID of the company.
     * @returns {Promise<void>}
     */
    async updateCompanyLogo(companyId: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, AdminCompaniesApi);
      const data = await apiInstance.updateCompanyLogo({
        file: this.logo ?? undefined,
        companyId,
      });

      if (data.url) {
        this.logo_url = data.url;
      }
    },

    /**
     * Update the company banner.
     * @param {string} companyId - The ID of the company.
     * @returns {Promise<void>}
     */
    async updateCompanyBanner(companyId: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, AdminCompaniesApi);
      await apiInstance.updateCompanyBanner({
        file: this.banner ?? undefined,
        companyId,
      });
    },

    /**
     * Fetch the company settings.
     * @param {string} company_id - The ID of the company.
     * @returns {Promise<void>}
     */
    async fetchCompanySettings(company_id: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, CompaniesApi);
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.getCompanySettings(company_id);
      const hiddenPrices = await companiesApi.getCompanyHiddenPrices(company_id);
      const { language } = await apiInstance.getCompanyLanguage({ companyId: company_id });
      const { logo, banner_url, ...company } = data.value;

      this.create_company = company;
      // set outlook calendar settings
      await this.fetchCalendarOutlookSettings(company_id);

      this.logo_url = logo;
      this.banner = banner_url;
      this.create_company.hide_catalog_prices = hiddenPrices.data.value.hide_catalog_prices;
      this.create_company.language = language;
    },

    /**
     * Fetch the Outlook calendar settings for a company.
     * @param {string} company_id - The ID of the company.
     * @returns {Promise<void>}
     */
    async fetchCalendarOutlookSettings(companyId: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, MicrosoftApi);
      try {
        const data = await apiInstance.findCompanyMicrosoftSettings({ companyId });

        this.create_company.client_id = data.clientId;
        this.create_company.client_secret = data.clientSecret;
        this.create_company.tenant_id = data.tenantId;
      } catch (error) {
        this.create_company.client_id = null;
        this.create_company.client_secret = null;
        this.create_company.tenant_id = null;
      }
    },
    /**
     * set outlook calendar settings for a company.
     * @param {string} company_id
     * @returns {Promise<void>}
     */
    async setCalendarOutlookSettings(companyId: string): Promise<void> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, MicrosoftApi);
      const { client_id, client_secret, tenant_id } = this.create_company;
      await apiInstance.setCompanyMicrosoftSettings({
        companyId,
        setCompanyMicrosoftSettingsDto: {
          clientId: client_id || "",
          clientSecret: client_secret || "",
          tenantId: tenant_id || "",
        },
      });
    },

    /**
     * Update the company information.
     * @param {string} companyId - The ID of the company.
     * @returns {Promise<void>}
     */
    async updateCompany(companyId: string) {
      const companiesApi = useCompaniesApi();
      const feedback = useFeedback();

      // delete outlook from payload and do a new call
      const { company_id, ...payload } = this.create_company;

      await this.setCalendarOutlookSettings(companyId);

      if (payload.client_id === "" || typeof payload.client_id === "string" || payload.client_id === null) {
        delete payload.client_id;
      }
      if (payload.tenant_id === "" || typeof payload.tenant_id === "string" || payload.tenant_id === null) {
        delete payload.tenant_id;
      }
      if (payload.client_secret === "" || typeof payload.client_secret === "string" || payload.client_secret === null) {
        delete payload.client_secret;
      }

      const { data, error } = await companiesApi.updateCompany(payload, companyId);
      if (data.value) {
        if (this.logo instanceof File || (!this.logo && !this.logo_url)) {
          await this.updateCompanyLogo(companyId);
        }
        if (this.banner && this.banner instanceof File) {
          await this.convertCompanyBanner(this.banner as File);
        }
        if (this.banner instanceof File || !this.banner) {
          await this.updateCompanyBanner(companyId);
        }
        this.show_create_modal = false;
        this.is_update = false;
        feedback.success("L'entreprise a bien été mise à jour", "small");
      } else {
        this.errors.update_company = error.value.data.message;
        feedback.error(`Une erreur est survenue lors de l'ajout`, "small");
      }
    },
  },
});
