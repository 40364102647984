import { useAuthStore } from "~/store/auth/auth.store";
/**
 * process.client when the middleware have to be used on client side (from navigation, nuxt-link etc)
 * else when the middleware have to be used on server side (from URL)
 * */
export default defineNuxtRouteMiddleware(async (to, from) => {
  /**
   * Global Middleware, it redirects if user is not logged in
   */
  const authStore = useAuthStore();
  const config = useRuntimeConfig();

  if (config.public.ENV !== "PROD") {
    // eslint-disable-next-line
    console.log("Auth Middleware");
  }

  const { meta } = to;
  const { fullPath } = from;
  const redirectCookie = useCookie("redirect_url");

  /**
   * Disabled AuthMiddle with this PageMeta
   * definePageMeta({
   *   isLoggedIn: false
   * });
   */

  if (typeof meta.isLoggedIn === "boolean" && meta.isLoggedIn === false) {
    if (from.fullPath !== "/") {
      if (!authStore.isLoggedIn()) {
        if (redirectCookie.value === "none") {
          redirectCookie.value = null;
        } else if (!from.fullPath.includes("/sso")) {
          redirectCookie.value = fullPath;
        }
      } else {
        redirectCookie.value = null;
      }
    }
  } else {
    await authStore.refreshToken();
    if (!authStore.isLoggedIn()) {
      if (process.client) {
        return abortNavigation();
      } else {
        return authStore.logOut();
      }
    }
  }
  // }
});
